// LIB JS

import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
import List from 'list.js';

// RUN INTO WEBSITE
flatpickr.localize(French);
window.flatpickr = flatpickr;
window.List = List;
window.showMessage = showMessage;
window.autocompleteAdresse = autocompleteAdresse;
window.hashchange = hashchange;

// FUNCTIONS

// Fonction qui affiche une notification en haut de la page pendant 3 secondes
// type : ( string ) class qu'aura l'élément ajouté ( valid, error, delete, warning )
// message : ( string ) message affiché
function showMessage(type, message) {
    var alert = $('<p>');
    alert.addClass('msg-info flash');
    alert.addClass(type);
    alert.html(message);

    $('body').append(alert);

    setTimeout(function () {
        alert.addClass('open');
    }, 100)

    setTimeout(function () {
        alert.removeClass('open');
    }, 3000)

    setTimeout(function () {
        alert.remove();
    }, 3500)
}

// INPUT FILE CUSTOM
var inputFileItems = document.querySelectorAll(".input-file");

for (var i = 0; i < inputFileItems.length; i++) {
    var fileInput = inputFileItems[i].children[0],
        button = inputFileItems[i].children[1],
        the_return = inputFileItems[i].children[2];

    button.addEventListener("keydown", function (event) {
        if (event.keyCode == 13 || event.keyCode == 32) {
            this.previousElementSibling.focus();
        }
    });

    button.addEventListener("click", function (event) {
        this.previousElementSibling.focus();
        return false;
    });

    fileInput.addEventListener("change", function (event) {
        var this_return = this.nextElementSibling.nextElementSibling;
        this_return.innerHTML = this.value.replace('C:\\fakepath\\', ' ');
    });
} // END

function hashchange(tag) {
    if (tag != '') {
        $('.nav-onglets > a').removeClass('actif');
        $('.onglet').removeClass('open');
        $(tag).addClass('open');
        $('.nav-onglets a[data-onglet="' + tag + '"]').addClass('actif');
    }
}

/**
 * Permet d'afficher le resultat du choix des correction d'addresse francaise
 */
function autocompleteAdresse(element) {
    $('#' + $(element).attr('data-id-cp')).val($(element).children('.cp').html());
    $('#' + $(element).attr('data-id-ville')).val($(element).children('.ville').html());
    $('#' + $(element).attr('data-id-adresse')).val($(element).children('.adresse').html());
    $('body').removeClass('mode-popup');
    $(element).parents('.popup').removeClass('open');
    if ($(element).attr('data-click') != '') {
        $('#' + $(element).attr('data-click')).click();
    }
}

$(function () {
    /**
     * Proposition des correctifs adresse pour la france
     */
    $('.check-adress').click(function () {
        $.ajax({
            url: 'https://api-adresse.data.gouv.fr/search/?q=' + $('#' + $(this).attr('data-id-adresse')).val() + ' ' + $('#' + $(this).attr('data-id-cp')).val() + ' ' + $('#' + $(this).attr('data-id-ville')).val() + '&limit=10',
            method: "GET",
            crossDomain: true,
            dataType: "JSON",
            gab: this
        }).done(function (data) {
            $('#prop-adresse-membre').html('');


            if (data.features.length == 0) {
                $('.results-adress').removeClass('on-search');
            } else {

                var dataidadresse = $(this.gab).attr('data-id-adresse');
                var dataidville = $(this.gab).attr('data-id-ville');
                var dataidcp = $(this.gab).attr('data-id-cp');
                var dataclick = $(this.gab).attr('data-click');


                $.each(data.features, function (key, element) {

                    $('#prop-adresse-membre').append(`
                    <a href="javascript: void 0 ;" onclick="autocompleteAdresse(this);"
                    data-id-adresse="${dataidadresse}"
                    data-id-cp="${dataidcp}"
                    data-id-ville="${dataidville}"
                    data-click="${dataclick}">
                        <span class='adresse'>${element.properties.name}</span>
                        <span class='cp'>${element.properties.postcode}</span>
                        <span class='ville'>${element.properties.city}</span>
                    </a>`);
                });
                $('.results-adress').addClass('on-search')
            }
        });
    });


    $('.textarea_mort').focus(function () {
        $(this).val($(this).val().replace(/†/g, '(=)'));
    });

    $('.textarea_mort').blur(function () {
        $(this).val($(this).val().replace(/\(=\)/g, '†'));
    });

    $('.toggle-nav').on('click', function () {
        $(this).toggleClass('menu-open');
        $('.sidebar').toggleClass('open');
    })

    // LOADER
    $('[data-loader]').on('click', function() {
        loader = $(this).attr('data-loader');
        loader.addClass('actif');
    })

    // Flatpickr
    $(".date").flatpickr({
        //dateFormat: ('Y-m-d'),
        altInput: true,
        altFormat: ('d/m/Y')
    });

    $(".datetime").flatpickr({
        altInput: true,
        altFormat: ('d/m/Y H:i'),
        enableTime: true,
        time_24hr: true
    });

    // POPUP
    $('*[data-popup]').click(function () {
        $('body').addClass('mode-popup');
        $('#' + $(this).attr('data-popup')).addClass('open');
    });

    $('.close-popup').click(function () {
        $('body').removeClass('mode-popup');
        $(this).parent('.popup').removeClass('open');
    });

    $('.switch-popup').click(function () {
        $(this).parents('.popup').removeClass('open');
    });

    // POPUP - LIST MIN
    $('.list').on('click', '*[data-popup-list]', function () {
        $('body').addClass('mode-popup');
        $('#' + $(this).attr('data-popup-list')).addClass('open');
    });

    $('.list').on('click', '.close-popup', function () {
        $('body').removeClass('mode-popup');
        $(this).parent('.popup').removeClass('open');
    });

    // TOGGLE SECTION
    $('.toggle-section').on('click', '> .toggle-head', function () {
        if ($(this).parent().hasClass("open")) {
            $('.toggle-section.open').removeClass('open');
        } else {
            $('.toggle-section.open').removeClass('open');
            $(this).parent().addClass('open');
        }
    });

    // TOGGLE ONE SHOT
    $('*[data-toggle]').click(function () {
        $('.toggle.open').removeClass('open');
        $('#' + $(this).attr('data-toggle')).addClass('open');
    });

    // LOADER ON LINK
    $('[data-loader-link]').on('click', function() {

        let link = $(this).attr('data-loader-link')
        
        $('#loader').addClass('actif')
        document.location.href=link

    })

    // ONGLETS
    $('*[data-onglet]').click(function () {
        $('.nav-onglets > a').removeClass('actif');
        $('.onglet').removeClass('open');
        $(this).addClass('actif');
        $($(this).attr('data-onglet')).addClass('open');
    });

    // haschange
    if (location.hash)
        hashchange(location.hash);

    $(window).bind('hashchange', function () {
        hashchange(location.hash);
    });

    // PREVENT KEY ENTER TO SUBMIT SEARCH MEMBRES
    $('.choix-membre').keydown(function(event){
        if(event.keyCode == 13) {
            event.preventDefault();
            return false;
        }
    });
}) // FIN DOC READY
